import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { HotelsTabsProps } from './types';
import * as Styled from './styles';
import { settings } from './const';

import Image from '../../atoms/Image';
import Tabs from '../../organisms/Tabs';

const HotelsTabs: React.FC<HotelsTabsProps> = ({ data }) => {
  const { items, isReversed } = data;
  const [selectedTabKey, setSelectedTabKey] = useState<string | undefined>(items[0].key);

  const selectedTab = items.find((item) => item.key === selectedTabKey);
  const { images = [] } = selectedTab || {};

  return (
    <Styled.HotelsTabsWrapper>
      <Styled.HotelsTabs {...{ isReversed }}>
        <Styled.HotelsTabsContent>
          <Styled.HotelsTabsContentInner>
            <Tabs
              initialyOpenKey={selectedTab?.key}
              onSelect={(key) => setSelectedTabKey(key)}
              {...{ items }}
            />
          </Styled.HotelsTabsContentInner>
        </Styled.HotelsTabsContent>
        <Styled.HotelsTabsMedia>
          <Styled.HotelsTabsSlider>
            <Slider {...settings}>
              {images.map(({ key, image }) => (
                <Styled.HotelsTabsSlide {...{ key }}>
                  <Image {...image} />
                </Styled.HotelsTabsSlide>
              ))}
            </Slider>
          </Styled.HotelsTabsSlider>
        </Styled.HotelsTabsMedia>
      </Styled.HotelsTabs>
    </Styled.HotelsTabsWrapper>
  );
};

export default HotelsTabs;
