import { graphql } from 'gatsby';
import React from 'react';

import HotelsTabs from '../../HotelsTabs';
import { getHref, getImage } from '../../../../utils/sanityTypes';

import { HotelsTabsItemProps } from '../../HotelsTabs/types';
import { HotelsTabsSectionProps } from './types';
import { MediaItemProps } from '../../../atoms/Image/types';

const HotelsTabsSection: React.VFC<HotelsTabsSectionProps> = ({ hotels, isReversed }) => (
  <HotelsTabs
    data={{
      isReversed: isReversed ?? false,
      // TODO: sanity types for hotel needs to be changed
      items: (hotels ?? []).map<HotelsTabsItemProps>((hotel) => {
        const { _key: key, heading, images, content, buttonLink, annotation } = hotel ?? {};
        const { link, buttonLabel } = buttonLink ?? {};

        return {
          key: key ?? '',
          heading: heading ?? '',
          content: content ?? undefined,
          annotation: annotation ?? undefined,
          buttonLink:
            {
              link: { to: getHref(link) },
              label: buttonLabel ?? '',
            } ?? {},
          images: (images ?? []).map<MediaItemProps>((currentImage) => {
            const { _key: imageKey, image, mobileImage } = currentImage ?? {};

            return {
              key: imageKey ?? '',
              image: getImage(image),
              mobileImage: getImage(mobileImage),
            };
          }),
        };
      }),
    }}
  />
);

export const fragment = graphql`
  fragment HotelsTabsSectionFragment on SanityHotelsTabsSection {
    _key
    _type
    isReversed
    hotels {
      _key
      heading: title
      title: heading
      images {
        _key
        image {
          ...ImageFragment
        }
        mobileImage {
          ...ImageFragment
        }
      }
      buttonLink {
        buttonLabel
        link {
          ...LinkFragment
        }
      }
      content: _rawDescription(resolveReferences: { maxDepth: 10 })
      annotation: _rawAnnotation(resolveReferences: { maxDepth: 10 })
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default HotelsTabsSection;
