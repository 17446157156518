import styled, { css } from 'styled-components';

import { HotelsTabsStyledProps } from './types';
import { Inner, FlexBox } from '../../styles/Grid/styles';
import media from '../../../common/MediaQueries';
import { CarouselPaginationDots } from '../../atoms/Carousel/styles';

export const HotelsTabsMedia = styled.div`
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  @media ${media.maxPhone} {
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    max-height: 33.75rem;
    height: ${({ theme }) => theme.heights.mobileWindow};
  }
  @media ${media.phone} {
    position: relative;
    width: 50%;
  }
`;

export const HotelsTabsSlider = styled.div`
  height: 100%;

  @media ${media.phone} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50vw;
  }
`;

export const HotelsTabsContent = styled(FlexBox)`
  padding: 5rem 0;
  @media ${media.phone} {
    padding: 10.625rem 2rem 10.625rem 0;
    width: 50%;
  }
`;

export const HotelsTabsContentInner = styled.div`
  width: 100%;
  @media ${media.phone} {
    max-width: 23.5rem;
  }
`;

export const HotelsTabsSlide = styled.div`
  height: 100%;
`;

export const HotelsTabs = styled(Inner)<HotelsTabsStyledProps>`
  ${CarouselPaginationDots} {
    position: absolute;
    bottom: 2rem;
    justify-content: flex-start;
    padding-left: 1.5rem;
  }
  @media ${media.phone} {
    display: flex;
    min-height: 100vh;
    max-height: 55rem;
  }

  ${({ isReversed }) =>
    isReversed &&
    css`
      @media ${media.phone} {
        flex-direction: row-reverse;
        ${HotelsTabsSlider} {
          left: unset;
          right: 0;
        }
        ${HotelsTabsContent} {
          padding: 10.625rem 0 10.625rem 2rem;
          justify-content: flex-end;
        }
      }
    `}
`;

export const HotelsTabsWrapper = styled.div`
  overflow: hidden;
`;
